import React, { useState, useEffect } from 'react';

const EditNames = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/get_all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.users);
                console.log(result.users)
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    function detectRole(item) {
        if (item.roles.length === 0) {
            return false
        } else {
            return true
        }
    }

    return (
        <div>
            <h2>Список пользователей</h2>
            <div className='namesList'>
                {data.map((item, index) => (
                    <div key={index} className='uni_user'>
                        <div style={{ marginLeft: "10px", minWidth: '100px' }} className='user_str'>
                            <p style={{ marginRight: "5px", fontWeight: 'bold' }}>ID:</p>
                            {item.user_id}
                        </div>
                        <div style={{ marginLeft: "10px", minWidth: '250px' }} className='user_str'>
                            <p style={{ marginRight: "5px", fontWeight: 'bold' }}>Telegram:</p>
                            <a href={`https://t.me/${item.user_name}` } target="_blank">
                                @{item.user_name}
                            </a>
                        </div>
                        <div style={{ marginLeft: "10px", minWidth: '100px' }} className='user_str'>
                            <p style={{ marginRight: "5px", fontWeight: 'bold' }}>Роль:</p>
                            {(detectRole(item)) ? 'admin' : "user"}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EditNames;
