// import userEvent from '@testing-library/user-event';
import { useAuth } from '../utils/AuthProvider';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('user');
        navigate('/login')
    }

    return (
        <div className='header header-main'>
            <div className='header header-l'>
                <label
                    style={{
                        fontFamily: 'Montserrat',
                        fontSize: '25px',
                        height: '32px',
                        fontWeight: '700',
                        padding: '5px 10px',
                        border: '2px solid black',
                        borderRadius: '10px',
                        userSelect: 'none',
                        marginRight: '15px',
                        whiteSpace: 'nowrap'
                    }}
                >re-agent-tool</label>
            </div>
            <div className='header header-r'>

                <div className='userTelegram header-item btn' title={user}>
                    <img className='avatar' src={user.photo_url} alt='' />
                    <div className='tg-titlename'>{user.username}</div>
                </div>
                <div className='logOut btn' onClick={logout} >
                    <svg className='logOutSvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                        <path d="M10.5 12L17 12" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.5 9L17 12" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.5 15L17 12" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17 17C17 19.2091 15.2091 20 13 20H10C7.79086 20 6 18.2091 6 16V8C6 5.79086 7.79086 4 10 4H13C15.2091 4 17 4.79086 17 7" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Header